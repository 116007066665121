// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attend-js": () => import("/opt/build/repo/src/pages/attend.js" /* webpackChunkName: "component---src-pages-attend-js" */),
  "component---src-pages-call-for-speakers-js": () => import("/opt/build/repo/src/pages/call-for-speakers.js" /* webpackChunkName: "component---src-pages-call-for-speakers-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/opt/build/repo/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-js": () => import("/opt/build/repo/src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-sponsors-js": () => import("/opt/build/repo/src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

